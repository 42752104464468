import axiosInstance from '../http/axiosSetup';
import { NewClaimInterface, ClaimHistoryPayload } from '../interfaces/Claim';

const tokenFunction = () => {
    const token = sessionStorage.getItem('token');
    return `${token}`;
};

export interface LanguageUpdate {
    languageId?: string;
    contractNumber: string;
    partnerId: string;
}
export default class UnitService {
    static userUpdate = (partnerId, contractNumber, body: User) =>
        axiosInstance
            .patch(`contracts/${partnerId}/${contractNumber}/address`, body)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    // static userUpdate = (body: User) =>
    //     axiosInstance
    //         .post('contract/address/update', body)
    //         .then((res) => {
    //             return res;
    //         })
    //         .catch((err) => {
    //             return err.response;
    //         });
    static getContract = (partnerId: string, contractNumber: string) =>
        axiosInstance
            .get(`contracts/${partnerId}/${contractNumber}`)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    static getDevice = (deviceId: string) =>
        axiosInstance
            .get(`devices/${deviceId}`)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    static languageUpdate = (body: LanguageUpdate) =>
        axiosInstance
            .post('/contract/language/update', body)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    static emailCheck = (body: Record<string, any>) =>
        axiosInstance
            .post(`validations/email-address-validation`, body)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
}
