import axiosInstance from '../http/axiosSetup';
import { LoginPayload } from '../interfaces/Login';

export default class AuthService {
    static performLogin = (body: LoginPayload) =>
        axiosInstance.post('authentications/microsite-login', body).then((res) => {
            return res;
        });
    static verifyCode = (body: LoginPayload) =>
        axiosInstance
            .post('authentications/microsite-verification', body)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                throw err;
            });

    static tokenRefresh = () => {
        axiosInstance
            .get('refresh/token')
            .then((res) => {
                const token = res?.headers?.authorization;
                sessionStorage.setItem('token', token);
                return token;
            })
            .catch((err) => {
                return err.response;
            });
    };
}
