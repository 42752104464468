import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { axiosRequestConfiguration } from './config';
import cookie from 'react-cookies';

const tokenFunction = () => {
    const token = sessionStorage.getItem('token');
    return `Bearer ${token}`;
};
const checkNumberFunction = () => {
    const checkNumber = sessionStorage.getItem('checkNumber');
    return checkNumber;
};
const initialization = (configs: AxiosRequestConfig): AxiosInstance => {
    const axiosInstance = axios.create(configs);

    axiosInstance.interceptors.request.use((request) => {
        if (request.url?.includes('claim-registration')) {
            request.headers.RequestId = checkNumberFunction();
            request.headers.Authorization = tokenFunction();
            // request.headers.post['Content-Type'] = 'multipart/form-data';
            return request;
        }
        if (!request.url?.includes('login') && !request.url?.includes('verification')) {
            request.headers.Authorization = tokenFunction();
        }
        return request;
    });
    return axiosInstance;
};

const axiosInstance = initialization(axiosRequestConfiguration);

export default axiosInstance;
