import AuthService from '@src/api/services/authService';
import UnitService, { LanguageUpdate } from '@src/api/services/unitService';
import RootState from '@src/interfaces/RootState';
import { put, select } from 'redux-saga/effects';
import { SET_LOADING } from '../ui/actions';

export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE';

export function* refreshToken() {
    yield put({ type: SET_LOADING, val: true });
    /**
     * Get request for TOKEN
     */
    const response = yield AuthService.tokenRefresh();
    yield put({ type: SET_LOADING, val: false });
}

export function* updateLanguage() {
    yield put({ type: SET_LOADING, val: true });

    const datas = yield select((state: RootState) => state?.form?.loginForm?.values);

    const languagePayload: LanguageUpdate = {
        languageId: 'NL',
        contractNumber: datas?.contractNumber,
        partnerId: datas?.partnerId,
    };

    const response = yield UnitService.languageUpdate(languagePayload);
    yield put({ type: SET_LOADING, val: false });
}
