// import { Claim } from '@src/interfaces/RootState';
// import { SET_CLAIM_HISTORY, SET_ACTIVE_CLAIM, SET_CUSTOMER, RESET } from './actions';

import { ALLOW_FIELDS_EDIT, RESET_BPOST, SET_BOX_NUMBER, SET_HOUSE_NUMBER, SET_LOCALITY, SET_STREET } from './actions';

interface BPost {
    locality: Array<object> | null;
    street: Array<object> | null;
    houseNumber: Array<object> | null;
    boxNumber: Array<object> | null;
    allowFieldsEdit: boolean;
}
const initialState: BPost = {
    locality: null,
    street: null,
    houseNumber: null,
    boxNumber: null,
    allowFieldsEdit: false,
};
/**
 * Claim related data
 */
export default function bPostReducer(state = initialState, action) {
    switch (action.type) {
        case SET_LOCALITY:
            return {
                ...state,
                locality: action.val,
            };
        case SET_STREET:
            return {
                ...state,
                street: action.val,
            };
        case SET_HOUSE_NUMBER:
            return {
                ...state,
                houseNumber: action.val,
            };
        case SET_BOX_NUMBER:
            return {
                ...state,
                boxNumber: action.val,
            };
        case ALLOW_FIELDS_EDIT: {
            return {
                ...state,
                allowFieldsEdit: true,
            };
        }
        case RESET_BPOST:
            return initialState;
        default:
            return state;
    }
}
