import React, { FC } from 'react';
import ReactDOM from 'react-dom';
// import { reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { Typography, Box, CircularProgress } from '@mui/material';
import { Backdrop } from '@mui/material';
import { useSelector } from 'react-redux';
import RootState from '@src/interfaces/RootState';

interface Loading {
    forceLoading?: boolean;
}
const LoadingScreen: FC<Loading> = ({ forceLoading }) => {
    /* STATES */

    /* VARIABLES */
    const { t } = useTranslation('common');
    const isLoading = useSelector((state: RootState) => state.ui.isLoading);

    /* FUNCTIONS */
    const content = (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={forceLoading ?? isLoading}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <CircularProgress color="inherit" sx={{ margin: '1rem auto' }} />
                <Typography align="center" className="loading__title">
                    {t('common.please_wait')}
                </Typography>
            </Box>
        </Backdrop>
    );

    /* USEEFFECTS */

    return ReactDOM.createPortal(content, document.getElementById('loader-hook')!);
};

export default LoadingScreen;
