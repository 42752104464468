// import { reduxForm } from 'redux-form';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from '@mui/material';
import { LoadingScreen } from '@MyComponents';
// import Footer from './footer';
import ResponsiveAppBar from './navigation/index';
import { AuthContext } from '@src/contexts/auth/authContext';
import { ReactNotifications } from 'react-notifications-component';
import Backdrop from '../ui/Backdrop';
import RootState from '@src/interfaces/RootState';
import { useSelector } from 'react-redux';

const Layout = ({ children }) => {
    /* STATES */

    /* VARIABLES */
    const isBackdrop = useSelector((state: RootState) => state?.ui?.backdrop);

    // const { t } = useTranslation('common');
    const {
        authState: { isAuthenticated },
    } = useContext<any>(AuthContext);
    /* FUNCTIONS */

    /* USEEFFECTS */

    return (
        <div className="layout">
            <ResponsiveAppBar />
            <ReactNotifications />
            {isBackdrop === true && <Backdrop />}
            <Container maxWidth="lg" sx={{ height: '70vh', padding: '1rem' }}>
                <LoadingScreen />
                {children}
            </Container>
        </div>
    );
};

export default Layout;

// export default reduxForm({
//     form: '{{form}}',
//     destroyOnUnmount: false,
//     forceUnregisterOnUnmount: true,
//     touchOnBlur: false
// })(index)
