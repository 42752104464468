import { FormControl, InputLabel, MenuItem, Select, ThemeProvider } from '@mui/material';
import React from 'react';
import * as SelectInterfaces from '@interfaces/SelectInterface';
import { theme } from './theme';
import { useTranslation } from 'react-i18next';

// // Pass an Array of objects with the select options (as options)
// // Reference => SelectInterfaces
// // [
// //   { name: 'EN', value: 'en', label: 'EN' },
// //   { name: 'FR', value: 'fr', label: 'FR' },
// //   { name: 'NL', value: 'nl', label: 'NL' },
// // ]

interface SelectFieldProps {
    options: SelectInterfaces.SelectList;
    meta: Record<string, string | boolean>;
    defaultValue?: string;
    value: string;
    placeholder: string;
    id: string;
    onChange: (value: SelectInterfaces.SelectListItem) => void;
}

type RenderSelectFieldProps = {
    input?: any;
    options: SelectInterfaces.SelectList;
    meta: Record<string, string | boolean>;
    className?: string;
    value: string;
    id: string;
    placeholder: string;
    defaultValue?: Record<string, string | number> | string | number;
};

const SelectField: React.FC<SelectFieldProps> = ({ onChange, value, options, placeholder, meta, id }) => {
    const { t } = useTranslation('common');
    // Function that runs when select an option
    const handleChange = (option) => {
        onChange(option);
    };
    // Iterate through options
    const optionItems = options.map((item) => {
        return (
            <MenuItem key={item.name} value={item.value}>
                {t(`claimtype.${item.name.toLowerCase().replace(' ', '_')}`)}
                {/* {item.name} */}
            </MenuItem>
        );
    });
    return (
        <ThemeProvider theme={theme}>
            <FormControl sx={{ marginTop: '16px', marginBottom: '8px' }} onChange={handleChange} fullWidth>
                <InputLabel error={meta.touched && meta.invalid ? true : false}>{placeholder}</InputLabel>
                <Select error={meta.touched && meta.invalid ? true : false} onChange={handleChange} labelId="demo-simple-select-label" id={id} value={value} defaultValue="en" label={placeholder}>
                    {optionItems}
                </Select>
            </FormControl>
        </ThemeProvider>
    );
};

const renderSelectField: React.FC<RenderSelectFieldProps> = ({ input, meta, options, placeholder, className, defaultValue, id }) => {
    return (
        <div
            className={
                meta.touched && meta.error
                    ? 'form__form-group-input-wrap form__form-group-input-wrap--error selectHasError z-1000'
                    : 'form__form-group-input-wrap form__form-group-input-wrap--error z-1000'
            }
        >
            <SelectField {...input} id={id} options={options} placeholder={placeholder} defaultValue={defaultValue} meta={meta} />
            {meta.touched && meta.error && (
                <span className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root">{meta.error}</span>
            )}
        </div>
    );
};

export default renderSelectField;
