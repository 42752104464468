import * as actionTypes from './actions';

const initialState = {
    // page: 1,
    // maxPages: 5, // Total pages of the journey - neccessary for the Wizard handling
    isLoading: false,
    // language: '', // not needed
    loggedIn: false,
    backdrop: false,
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function userReducer(state = initialState, action) {
    switch (action.type) {
        // case actionTypes.SET_PAGE:
        //     return {
        //         ...state,
        //         page: action.val,
        //     };
        case actionTypes.SET_LOADING:
            return {
                ...state,
                isLoading: action.val,
            };
        case actionTypes.SET_BACKDROP:
            return {
                ...state,
                backdrop: action.val,
            };
        case actionTypes.RESET:
            return initialState;
        default:
            return state;
    }
}
