import { configureStore } from '@reduxjs/toolkit';
import { applyMiddleware, combineReducers, compose, legacy_createStore as createStore } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import uiReducer from './ui/reducer';
// import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import bPostReducer from './bpost/reducer';
import claimReducer from './claim/reducer';
import errorReducer from './error/reducer';
import rootSaga from './sagas';
const sagaMiddleWare = createSagaMiddleware();
const reducer = combineReducers({
    bPost: bPostReducer,
    claim: claimReducer,
    form: reduxFormReducer,
    error: errorReducer,
    ui: uiReducer,
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const logger = (store) => {
    return (next) => {
        return (action) => {
            // console.log('[Middleware] Dispatching', action);
            const result = next(action);
            // console.log('[Middleware] next state', store.getState());
            return result;
        };
    };
};

const composeEnhancers = (window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose) || compose;

const store = createStore(reducer, composeEnhancers(applyMiddleware(sagaMiddleWare, logger, thunk)));
sagaMiddleWare.run(rootSaga);
export default store;
