// import { reduxForm } from 'redux-form';
import { handleGeneralError } from '@src/api/errorHandling/ErrorHandling';
import { GeneralErrors } from '@src/api/models/Error';
import RootState from '@src/interfaces/RootState';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { setNotification } from '../ui/Notifications';

const MainWrapper = ({ children }) => {
    /* STATES */

    /* VARIABLES */
    const { t } = useTranslation('common');
    const error = useSelector((state: RootState) => state?.error);
    /* FUNCTIONS */

    /* API FUNCTIONS */

    /* USEEFFECTS */
    useEffect(() => {
        let title = 'test';
        let appearance = 'warning';

        switch (error?.errorObject?.errorCode) {
            case 200:
                appearance = 'success';
                title = t('error_handling.success');
                break;
            case 401:
                appearance = 'warning';
                title = t('error_handling.warning');
                break;
            case 403:
                appearance = 'warning';
                title = t('error_handling.warning');
                break;
            case 406:
                appearance = 'warning';
                title = t('error_handling.warning');
                break;
            case 412:
                appearance = 'warning';
                title = t('error_handling.warning');
                break;
            case 500:
                appearance = 'danger';
                title = t('error_handling.error');
                break;
            default:
                appearance = 'danger';
                title = t('error_handling.error');
        }
        if (error.errorObject !== null) {
            let target;
            if (error.errorObject?.dictionaryObject === 'login_errors') {
                if (error.errorObject?.errorCode == 401) {
                    target = (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: t('login_errors.UNAUTHORIZED', {
                                    email: `<a class="primary-text" href=mailto:${t('contact.contact_email')}>${t('contact.contact_email')}</a>`,
                                    phone: `<a class="primary-text" href=tel:${t('contact.contact_phone')}>${t('contact.contact_phone')}</a>`,
                                }),
                            }}
                        />
                    );
                }
                if (error.errorObject?.errorCode == 412) {
                    target = (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: t('login_errors.PRECONDITIONAL_FAILED', {
                                    email: `<a class="primary-text" href=mailto:${t('contact.contact_email')}>${t('contact.contact_email')}</a>`,
                                    phone: `<a class="primary-text" href=tel:${t('contact.contact_phone')}>${t('contact.contact_phone')}</a>`,
                                }),
                            }}
                        />
                    );
                }
            } else {
                target = t(`${error?.errorObject?.dictionaryObject}.${handleGeneralError(GeneralErrors, error?.errorObject?.errorCode)}`);
            }

            setNotification(title, target, appearance);
        }
    }, [error]);

    return <main className="main-wrapper">{children}</main>;
};

export default MainWrapper;

// export default reduxForm({
//     form: '{{form}}',
//     destroyOnUnmount: false,
//     forceUnregisterOnUnmount: true,
//     touchOnBlur: false
// })(index)
