// import { axiosInstanceBpost } from './../http/axiosSetup';
import qs from 'qs';
import axiosInstance from '../http/axiosSetup';
export interface QueryParams {
    requestType: string;
    id: string | number;
    query: string;
    maxNumberOfSuggestions: string | number;
    postalCode: string;
    localityName: string;
    streetName: string;
    houseNumber: string;
    form: string;
}

export default class BpostService {
    static bpostCheck = (queryParams: Partial<QueryParams>) =>
        axiosInstance
            .get(`/bpost/address-validator?${qs.stringify(queryParams, { encode: true })}`)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                // console.error(err);
                throw err;
            });
}
