import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch } from 'react-redux';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Backdrop = () => {
    const [transitiOn, setTransitiOn] = useState(false);
    const dispatchStore = useDispatch();

    const backdropClickHandler = () => {
        dispatchStore({ type: 'SET_MODAL', val: false });
    };
    useEffect(() => {
        setTimeout(() => {
            setTransitiOn(true);
        }, 10);
        return () => {
            setTimeout(() => {
                setTransitiOn(false);
            }, 10);
        };
    }, []);
    return <div className={`backdrop ${transitiOn && 'show'}`} onClick={backdropClickHandler}></div>;
};

const RenderBackdrop = (props) => {
    return <>{ReactDOM.createPortal(<Backdrop {...props} />, document.getElementById('backdrop-root') as HTMLElement)}</>;
};
export default RenderBackdrop;
