import axiosInstance from '../http/axiosSetup';
import { NewClaimInterface, ClaimHistoryPayload } from '../interfaces/Claim';
const tokenFunction = () => {
    const token = sessionStorage.getItem('token');
    return `${token}`;
};
export default class ClaimService {
    static getClaimTypes = () =>
        axiosInstance
            .get('/claim/type/list')
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
    static getClaimHistory = (body: ClaimHistoryPayload) =>
        axiosInstance
            .post('/proximus/claim/list', body)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });

    static addNewClaim = (body: Record<string, any>) =>
        axiosInstance
            .post('/microsites/claim-registration', body)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return err.response;
            });
}
