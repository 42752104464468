import { ClaimHistoryInterface } from './../interfaces/Claim';

class ClaimHistoryItemModel implements ClaimHistoryInterface {
    clm_id: string | number;
    breakdown_date: string;
    clm_type: string;
    clm_status: string | number;
    constructor(item) {
        this.clm_id = item?.clm_id;
        this.breakdown_date = item.breakdown_date;
        this.clm_type = item.clm_type;
        this.clm_status = item.clm_status;
    }
}

export default ClaimHistoryItemModel;
