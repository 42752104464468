export default {
    buttonVariant: 'text', // [text, contained, outlined]
    selectVariant: 'filled', // [filled, outlined, standard]
    fieldVariant: 'outlined', // [filled, outlined, standard]

    // Color themes
    primaryColor: '#478FA2',
    secondaryColor: 'rgba(221, 221, 221, .8)',

    // ButtonThemes
    buttonPrimary: {
        // TODO Button Primary
        backgroundColor: '#478FA2',
        color: 'white',
    },
    buttonSecondary: {
        // TODO Button Secondary
        backgroundColor: 'transparent',
        color: 'black',
    },
    buttonTertiary: {
        // TODO Button Tertiary
        backgroundColor: 'transparent',
        color: 'black',
    },
};
