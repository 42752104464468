import { ClaimHistoryPayload } from '@src/api/interfaces/Claim';
import ClaimHistoryItem from '@src/api/models/ClaimHistoryItem';
import ClaimTypeModel from '@src/api/models/ClaimTypeModel';
import ClaimService from '@src/api/services/claimService';
import { SET_ERROR_OBJECT } from '@src/redux/error/actions';
import { I18nContext } from 'react-i18next';
import { put } from 'redux-saga/effects';
import { SET_LOADING } from '../ui/actions';

/** SAGA actions */
export const GET_CLAIM_TYPE_LIST = 'GET_CLAIM_TYPE_LIST';
export const GET_CLAIM_HISTORY = 'GET_CLAIM_HISTORY';

/** Reducer Actions */
export const SET_CLAIM_TYPE_LIST = 'SET_CLAIM_TYPE_LIST';
export const SET_CLAIM_NUMBER = 'SET_CLAIM_NUMBER';
export const SET_CLAIM_HISTORY = 'SET_CLAIM_HISTORY';
export const SET_ACTIVE_CLAIM = 'SET_ACTIVE_CLAIM';
export const RESET = 'RESET';

/**
 * Claim type list
 */
export function* getClaimTypeList() {
    yield put({ type: SET_LOADING, val: true });

    /**
     * Get request for Claim types
     */
    const response = yield ClaimService.getClaimTypes();

    if (response.status == 200) {
        const ClaimTypeList: Array<any> = [];
        /**
         * Must filter on AD, OXYD, THEFT by id
         */
        const claimTypes = response?.data?.filter((ct) => ct.id === 1 || ct.id === 4 || ct.id === 5);

        claimTypes.forEach((item) => {
            const claimTypeItem = new ClaimTypeModel(item);
            ClaimTypeList.push(claimTypeItem);
        });

        yield put({ type: SET_CLAIM_TYPE_LIST, val: ClaimTypeList });
    } else {
        yield put({ type: SET_ERROR_OBJECT, val: { dictionaryObject: 'common_errors', errorCode: response?.status } });
    }

    yield put({ type: SET_LOADING, val: false });
}

/**
 * Claim History
 */

export function* getClaimHistory() {
    yield put({ type: SET_LOADING, val: true });
    /**
     * Collect data for API request payload
     */
    const payload: ClaimHistoryPayload = {
        con_number: JSON.parse(sessionStorage.getItem('contract') as string)?.contractNumber ?? '',
        prt_id: JSON.parse(sessionStorage.getItem('contract') as string)?.partnerId ?? '',
    };

    /**
     * Call API
     */
    const response = yield ClaimService.getClaimHistory(payload);

    if (response.status == 200) {
        const historyArray: Array<ClaimHistoryItem> = [];
        let openedClaimCount = 0;
        let earliestClaim = 0;
        for (let i = 0; i <= response?.data.length; i++) {
            if (response?.data[i] && response?.data[i].orders) {
                for (let k = 0; k < response?.data[i].orders.length; k++) {
                    if (response?.data[i].orders[k].orderStatus && response?.data[i].orders[k].orderStatus.statusCode == 'DESP') {
                        response.data[i].hasDESP = true;
                    }
                }
            }
            if (response?.data[i] != undefined && response?.data[i].clm_status < 70 && response?.data[i].hasDESP != true) {
                if (response?.data[i].clm_id < earliestClaim || earliestClaim == 0) {
                    earliestClaim = response?.data[i].clm_id;
                }
                openedClaimCount++;
            }
        }
        /**
         * Basically redux stores NULL value to keep loading till the error responded.
         * When response's length more than 0, foreach on it
         */
        if (response?.data?.length > 0) {
            response?.data.forEach((item) => {
                const historyItem = new ClaimHistoryItem(item);

                historyArray.push(historyItem);
            });
        }
        if (openedClaimCount > 0) {
            yield put({ type: SET_ACTIVE_CLAIM, val: earliestClaim });
        } else {
            yield put({ type: SET_ACTIVE_CLAIM, val: null });
        }
        yield put({ type: SET_CLAIM_HISTORY, val: historyArray });
    }
    if (response.status === 401) {
        console.log('Bad credentials');
    }
    yield put({ type: SET_LOADING, val: false });
}
