import React from 'react';
import Layout from '@src/components/layout/Layout';
import { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from '@src/contexts/auth/protectedRoute';
import LoadingScreen from '@src/components/ui/Loader';

// const NotFound404 = lazy(() => import('@src/pages/notFound'));
const Contact = lazy(() => import('@src/pages/contact/index'));
const ClaimHistory = lazy(() => import('@src/pages/claimHistory'));
const NewClaim = lazy(() => import('@src/pages/newClaim'));
const Dashboard = lazy(() => import('@src/pages/dashboard'));
const SuccessPage = lazy(() => import('@src/pages/success'));
const Login = lazy(() => import('@src/pages/login/index'));
// const Home = lazy(() => import('@src/pages/index'));

const Router = () => {
    return (
        <>
            <Suspense fallback={<LoadingScreen forceLoading={true} />}>
                <Switch>
                    <Route exact path="/">
                        <Layout>
                            <Suspense fallback={<LoadingScreen forceLoading={true} />}>
                                <>
                                    <Login />
                                </>
                            </Suspense>
                        </Layout>
                    </Route>
                    <PrivateRoute exact path="/dashboard">
                        <Layout>
                            <Suspense fallback={<LoadingScreen forceLoading={true} />}>
                                <Dashboard />
                            </Suspense>
                        </Layout>
                    </PrivateRoute>
                    <PrivateRoute exact path="/new-claim">
                        <Layout>
                            <Suspense fallback={<LoadingScreen forceLoading={true} />}>
                                <NewClaim />
                            </Suspense>
                        </Layout>
                    </PrivateRoute>
                    <PrivateRoute exact path="/claim-history">
                        <Layout>
                            <Suspense fallback={<LoadingScreen forceLoading={true} />}>
                                <ClaimHistory />
                            </Suspense>
                        </Layout>
                    </PrivateRoute>
                    <PrivateRoute exact path="/success">
                        <Layout>
                            <Suspense fallback={<LoadingScreen forceLoading={true} />}>
                                <SuccessPage />
                            </Suspense>
                        </Layout>
                    </PrivateRoute>
                </Switch>
            </Suspense>
        </>
    );
};

export default Router;
